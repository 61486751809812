<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/locations">Standorte</router-link></li>
              <li>{{ location.name }}</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/locations" class="button button-grey button-small">Alle Standorte ›</router-link></p>
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li class="active"><router-link :to="'/admin/locations/'+this.$route.params.id">Stammdaten</router-link></li>
            <li><router-link :to="'/admin/locations/'+this.$route.params.id+'/hours'">Öffnungszeiten</router-link></li>
            <li><router-link :to="'/admin/locations/'+this.$route.params.id+'/users'">Mitarbeiter</router-link></li>
          </ul>

          <form @submit="update_location" class="form" method="post" style="margin-top:20px">

            <p v-if="location.image"><img :src="location.image" width="200"></p>

            <div class="form-wrap">
              <label>Bild</label>
              <input type="file" @change="set_image">
            </div>

            <div class="form-wrap">
              <label for="name">Name</label>
              <input v-model="location.name" class="form-input" type="text" name="name" id="name">
            </div>

            <div class="form-wrap">
              <label for="description">Beschreibung</label>
              <textarea v-model="location.description" rows="5" class="form-input" type="text" name="description" id="description"></textarea>
            </div>

            <div class="form-wrap select-wrap">
              <label for="status">Status</label>
              <select v-model="location.status" class="select-wrap">
                <option value="enabled">Aktiviert</option>
                <option value="disabled">Deaktiviert</option>
              </select>
            </div>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="form-wrap">
                  <label for="contact_email">Kontakt E-Mail</label>
                  <input v-model="location.contact_email" class="form-input" type="text" name="contact_email" id="contact_email">
                </div>
              </div>
              <div class="col-12">
                <div class="form-wrap">
                  <label for="contact_phone">Kontakt Telefon</label>
                  <input v-model="location.contact_phone" class="form-input" type="text" name="contact_phone" id="contact_phone">
                </div>
              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-18">
                <div class="form-wrap">
                  <label for="street_name">Straße</label>
                  <input v-model="location.street_name" class="form-input" type="text" name="street_name" id="street_name">
                </div>
              </div>
              <div class="col-6">
                <div class="form-wrap">
                  <label for="street_number">Hausnummer</label>
                  <input v-model="location.street_number" class="form-input" type="text" name="street_number" id="street_number">
                </div>
              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-6">
                <div class="form-wrap">
                  <label for="zip_code">Postleitzahl</label>
                  <input v-model="location.zip_code" class="form-input" type="text" name="zip_code" id="zip_code">
                </div>
              </div>
              <div class="col-18">
                <div class="form-wrap">
                  <label for="city">Stadt</label>
                  <input v-model="location.city" class="form-input" type="text" name="city" id="city">
                </div>
              </div>
            </div>

            <div class="form-wrap">
              <label for="commission_pct">Provision</label>
              <input v-model="location.commission_pct" class="form-input" type="text" name="commission_pct" id="commission_pct">
            </div>

            <div class="form-wrap">
              <label for="zip_code">Standard: Inklusivkilometer</label>
              <input v-model="location.km_inclusive" class="form-input" type="text" name="zip_code" id="zip_code">
            </div>

            <p>
              <input type="submit" value="Standort speichern ›" class="button button-orange button-small">
            </p>

          </form>


        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      errors: [],
      location: {},
      temp_image: ""
    }
  },
  methods: {
    get_location() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.location = response.data.location;
      })
      .catch(error => {
        console.log(error);
      })

    },
    update_location: function (e) {
      e.preventDefault();

      var form_data = new FormData();
      form_data.append("name", this.location.name);
      form_data.append("description", this.location.description);
      form_data.append("status", this.location.status);
      form_data.append("contact_email", this.location.contact_email);
      form_data.append("contact_phone", this.location.contact_phone);
      form_data.append("street_name", this.location.street_name);
      form_data.append("street_number", this.location.street_number);
      form_data.append("zip_code", this.location.zip_code);
      form_data.append("city", this.location.city);
      form_data.append("km_inclusive", this.location.km_inclusive);
      form_data.append("commission_pct", this.location.commission_pct);

      form_data.append("image", this.temp_image);

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/locations/'+this.$route.params.id, form_data, { headers: { Authorization: this.$store.getters.getToken }})
      .then(() => {
        this.get_location();
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.',
        });
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      })

    },
    set_image(event) {
      this.temp_image = event.target.files[0];
    }
  },
  mounted () {
    this.get_location();
  }
}
</script>

<style lang="scss">

</style>
